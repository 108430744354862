export const baseURL = process.env.NEXT_PUBLIC_DB_URL || 'http://localhost:3000';
export const imgOriginalURL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL + '/property/ORIGINAL/';
export const imgLargeURL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL + '/property/LARGE/';
export const imgMediumURL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL + '/property/MEDIUM/';
export const imgSmallURL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL + '/property/SMALL/';

let authToken = {};

interface ApiTypes {
  (url: string, method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', body?: any): Promise<any>;
}

export const api: ApiTypes = async (url, method, body) => {
  try {
    const response = await fetch(baseURL + url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'max-age=31536000',
        ...authToken
      },
      cache: 'no-store',
      body: body ? JSON.stringify(body) : undefined
    });

    if (!response.ok) {
      console.error(response);
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    throw err;
  }
};

export const handleSetAuthToken = (t?: string) => {
  const token = t || localStorage.getItem('token');

  authToken = {
    Authorization: `Bearer ${token}`
  };
};
