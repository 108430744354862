'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeDarkMode } from '@/Store/slices/global.slice';
import moonIcon from '@/assets/icons/dark_mode_moon_icon.svg';
import sunIcon from '@/assets/icons/dark_mode_sun_icon.svg';
import { FiltersBurgerIcon } from '@/assets/svgComponents/FiltersBurgerIcon';
import { MenuArrow } from '@/assets/svgComponents/menuArrow';
import { SearchIcon } from '@/assets/svgComponents/searchIcon';
import { Link } from '@/helpers/navigation';
import { langKey } from '@/helpers/utils';
import { DistrictsType, districts } from '@/static/districts';
import { RegionsType, regions } from '@/static/regions';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import {
  handleChangeLabelValue,
  handleChangeLabelValueArr,
  handleChangePriceMinMax,
  handleIdArrChange
} from './actions';
import { Filter7 } from './filter7';
import { FIS, FIS7, filterData } from './filterData';
import s from './style.module.scss';
import { FIStype, Filter7Type } from './types';

export const Filters = () => {
  const dispatch = useTDispatch();
  const t = useTranslations('');
  const CL = useLocale();
  const { darkMode } = useTSelector((state) => state.global);
  const [filter, setFilter] = useState<FIStype>({ ...FIS });
  const { filter1, filter2, filter3, filter4, filter5, filter6 } = filter;
  const [filter7, setFilter7] = useState<Filter7Type>({ ...FIS7 });
  const [filteredDistricts, setFilteredDistricts] = useState(
    districts.filter((item) => filter3.includes(item.regionId))
  );
  const [bodyScroll, setBodyScroll] = useState(0);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState('');
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const [rendered, setRendered] = useState(false);

  const toggleDarkMode = () => {
    if (darkMode === 'dark') {
      dispatch(changeDarkMode('light'));
    } else {
      dispatch(changeDarkMode('dark'));
    }
  };

  const toggleBurger = (e: React.MouseEvent) => {
    e.stopPropagation();
    setBurgerIsOpen(!burgerIsOpen);
  };

  const handleCloseBurger = () => setBurgerIsOpen(false);

  const handleCloseSubMenu = () => setSubMenuIsOpen('');

  const handleOpenSubMenu = (e: React.MouseEvent, menu: string) => {
    e.stopPropagation();
    if (subMenuIsOpen === menu) return handleCloseSubMenu();
    setSubMenuIsOpen(menu);
  };

  const handleSaveFilters = () => {
    localStorage.setItem('filter', JSON.stringify(filter));
    localStorage.setItem('filter7', JSON.stringify(filter7));
  };

  const handleHideFilters = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setBodyScroll(scrollTop);
  };

  useEffect(() => {
    setFilteredDistricts(districts.filter((item) => filter3.includes(item.regionId)));
  }, [filter3]);

  useEffect(() => {
    const filters = `?minFloor=${filter7.minFloor}&maxFloor=${filter7.maxFloor}&minTotalFloor=${
      filter7.minTotalFloor
    }&maxTotalFloor=${filter7.maxTotalFloor}&minTotalArea=${filter7.minTotalArea}&maxTotalArea=${
      filter7.maxTotalArea
    }&roomCounts=${filter5.slice(1).map(({ value }) => value)}&minPrice=${filter6.min}&maxPrice=${
      filter6.max
    }&regionIds=${filter3}&districtIds=${filter4}&ids=${filter7.id}&propertyActionType=${
      filter1.value === 'sale' ? 'DAILY' : 'DAILY'
    }&buildingTypes=${filter7.buildType.map(({ value }) => value)}&isNewConstruction=${
      filter7.isNewConstruction
    }&isOnlyWithImages=${filter7.isOnlyWithImages}&visibilityStatuses=${filter7.status.map(
      ({ value }) => value
    )}`;
    const filteredQueries = filters
      .split('&')
      .filter((query) => query.split('=')[1] !== '')
      .join('&');

    setSearchParams(filteredQueries);
  }, [filter, filter7]);

  useEffect(() => {
    if (!darkMode) return;
    document.body.setAttribute('data-theme', darkMode);
    localStorage.setItem('theme', darkMode);
  }, [darkMode]);

  useEffect(() => {
    setRendered(true);

    if (window.location.pathname.split('').length < 4) {
      localStorage.removeItem('filter');
      localStorage.removeItem('filter7');
    }

    const localFilter = JSON.parse(localStorage.getItem('filter') || '{}');
    const localFilter7 = JSON.parse(localStorage.getItem('filter7') || '{}');

    if (Object.keys(localFilter).length) {
      setFilter(localFilter);
      setFilter7(localFilter7);
    }

    window.addEventListener('click', handleCloseBurger);
    window.addEventListener('click', handleCloseSubMenu);
    document.addEventListener('scroll', handleHideFilters);
    return () => {
      window.removeEventListener('click', handleCloseBurger);
      window.removeEventListener('click', handleCloseSubMenu);
      document.removeEventListener('scroll', handleHideFilters);
    };
  }, []);

  useEffect(() => {
    if (burgerIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [burgerIsOpen]);

  return (
    <div
      className={`${s.filtersContainer} ${bodyScroll > 70 && 'hide'}`}
      onClick={(e) => {
        e.stopPropagation();
        handleCloseSubMenu();
      }}>
      <div>
        <button className={s.filtersBurger} onClick={toggleBurger}>
          <FiltersBurgerIcon fill={darkMode} />
          <span>filters</span>
        </button>
        <ul className={`${s.filters} ${burgerIsOpen && 'open'}`}>
          {/* FILTER 1 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter1'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter1')}>
            <span className={s.title}>
              <span>{t(filter1.label)}</span> <MenuArrow />
            </span>
            {rendered && (
              <form className={s.menu}>
                {filterData.filter1.map(({ label, value }) => (
                  <label
                    key={label}
                    onClick={() => handleChangeLabelValue(setFilter, 'filter1', label, value)}>
                    <input
                      type='radio'
                      name='r1'
                      defaultChecked={filter1.label === label}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <span>{t(label)}</span>
                  </label>
                ))}
              </form>
            )}
          </li>
          {/* FILTER 2 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter2'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter2')}>
            <span className={s.title}>
              <span>{t(filter2.label)}</span> <MenuArrow />
            </span>
            {rendered && (
              <form className={s.menu}>
                {filterData.filter2.map(({ label, value }) => (
                  <label
                    key={label}
                    onClick={() => handleChangeLabelValue(setFilter, 'filter2', label, value)}>
                    <input
                      type='radio'
                      name='r2'
                      defaultChecked={filter2.label === label}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <span>{t(label)}</span>
                  </label>
                ))}
              </form>
            )}
          </li>
          {/* FILTER 3 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter3'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter3')}>
            <span className={s.title}>
              <span>
                {!filter3.length
                  ? t('address_region_label')
                  : filter3.map((regId, i) => {
                      const selected: RegionsType = regions.find((item) => item.id === regId)!;
                      const title = selected[langKey[CL]?.title as keyof RegionsType];
                      return i > 0 ? `, ${title}` : title;
                    })}
              </span>
              <MenuArrow />
            </span>
            {rendered && (
              <form className={s.menu}>
                {regions.map((item: RegionsType) => {
                  const title = item[langKey[CL]?.title as keyof RegionsType];
                  return (
                    <label
                      key={item.id}
                      onClick={() => handleIdArrChange(setFilter, filter, 'filter3', item.id)}>
                      <input
                        type='checkbox'
                        defaultChecked={filter3.includes(item.id)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <span>{title}</span>
                    </label>
                  );
                })}
              </form>
            )}
          </li>
          {/* FILTER 4 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter4'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter4')}>
            <span className={s.title}>
              <span>
                {(filteredDistricts.find((item) => item.id === filter4[0])?.[
                  langKey[CL]?.title as keyof DistrictsType
                ] || t('address_district_label')) + (filter4.length > 1 ? ', ...' : '')}
              </span>
              <MenuArrow />
            </span>
            {!!filteredDistricts.length && rendered && (
              <form className={s.menu}>
                {filteredDistricts.map((item: DistrictsType) => {
                  const title = item[langKey[CL]?.title as keyof DistrictsType];

                  return (
                    <label
                      key={item.id}
                      onClick={() => handleIdArrChange(setFilter, filter, 'filter4', item.id)}>
                      <input
                        type='checkbox'
                        defaultChecked={filter4.includes(item.id)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <span>{title}</span>
                    </label>
                  );
                })}
              </form>
            )}
          </li>
          {/* FILTER 5 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter5'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter5')}>
            <span className={s.title}>
              <span>
                {filter5.length < 2
                  ? t('room')
                  : filter5.map((item, i) => (i > 1 ? ', ' + item.label : item.label))}
              </span>
              <MenuArrow />
            </span>
            {rendered && (
              <form className={s.menu}>
                {filterData.filter5.map(({ label, value }) => (
                  <label
                    key={label}
                    onClick={() =>
                      handleChangeLabelValueArr(setFilter, filter, 'filter5', label, value)
                    }>
                    <input
                      type='checkbox'
                      defaultChecked={!!filter5.find((item) => item.label === label)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <span>{label}</span>
                  </label>
                ))}
              </form>
            )}
          </li>
          {/* FILTER 6 */}
          <li
            className={`${s.button} ${subMenuIsOpen === 'filter6'}`}
            onClick={(e) => handleOpenSubMenu(e, 'filter6')}>
            <span className={s.title}>
              <span>
                <b>{filter6.min.toLocaleString() || t('min_short')}</b>-
                <b>{filter6.max.toLocaleString() || t('max_short')}</b>
              </span>
              <MenuArrow />
            </span>

            <div className={s.minMaxMenu} onClick={(e) => e.stopPropagation()}>
              {rendered && (
                <div className={s.priceMinMax}>
                  <h3>
                    {t('price')} ({t('min_short')}-{t('max_short')})
                  </h3>
                  <input
                    type='number'
                    autoComplete='one-time-code'
                    placeholder={t('min_short')}
                    name='minTotalArea'
                    value={filter6.min}
                    onChange={(e) =>
                      handleChangePriceMinMax(setFilter, 'filter6', 'min', e.target.value)
                    }
                  />
                  <input
                    type='number'
                    autoComplete='one-time-code'
                    placeholder={t('max_short')}
                    name='maxTotalArea'
                    value={filter6.max}
                    onChange={(e) =>
                      handleChangePriceMinMax(setFilter, 'filter6', 'max', e.target.value)
                    }
                  />
                </div>
              )}
            </div>
          </li>
          {/* FILTER 7 */}
          <Filter7
            searchParams={searchParams}
            filter={filter}
            filter7={filter7}
            rendered={rendered}
            setFilter7={setFilter7}
            subMenuIsOpen={subMenuIsOpen}
            handleOpenSubMenu={handleOpenSubMenu}
          />
          <li className={s.submitBtn} onClick={handleSaveFilters}>
            <Link href={`/property-filter?${searchParams}`} title='search' className={s.submitBtn1}>
              <SearchIcon />
            </Link>
            <Link href={`/property-filter?${searchParams}`} title='search' className={s.submitBtn2}>
              {t('search')}
            </Link>
          </li>
        </ul>
        <button className={s.darkMode} onClick={toggleDarkMode}>
          {darkMode === 'dark' ? (
            <Image src={sunIcon} alt='light' />
          ) : (
            <Image src={moonIcon} alt='dark' />
          )}
          <span>{darkMode === 'dark' ? 'Light mode' : 'Dark Mode'}</span>
        </button>
      </div>
    </div>
  );
};
