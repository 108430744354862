import { floors } from '@/helpers/utils';
import { FIStype } from './types';

export const FIS = {
  filter1: {
    label: 'sale',
    value: 'sale'
  },
  filter2: {
    label: 'apartment',
    value: 'apartments'
  },
  filter3: [5],
  filter4: [],
  filter5: [{ label: '' }],
  filter6: { min: '', max: '' }
} as FIStype;

export const FIS7 = {
  id: '',
  address: '',
  isNewConstruction: false,
  isOnlyWithImages: false,
  minFloor: '',
  maxFloor: '',
  minTotalFloor: '',
  maxTotalFloor: '',
  minTotalArea: '',
  maxTotalArea: '',
  buildType: [],
  status: []
};

export const filterData = {
  filter1: [
    {
      label: 'sale',
      value: 'sale'
    },
    {
      label: 'rent',
      value: 'rent'
    }
  ],

  filter2: [
    {
      label: 'apartment',
      value: 'apartments'
    },
    {
      label: 'house',
      value: 'house'
    },
    {
      label: 'commercial',
      value: 'commercial'
    },
    {
      label: 'land',
      value: 'land'
    }
  ],

  filter5: [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    },
    {
      label: '4',
      value: '4'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '6',
      value: '6'
    },
    {
      label: '7+',
      value: '7+'
    }
  ],

  filter7: {
    floor: floors,

    buildType: [
      { label: 'panel', value: 'panel' },
      { label: 'stone', value: 'stone' },
      { label: 'monolith', value: 'monolith' },
      { label: 'other', value: 'other' }
    ],

    status: [
      { label: 'reconstructed', value: 'reconstructed' },
      { label: 'good', value: 'good' },
      { label: 'start', value: 'start' }
    ]
  }
};
