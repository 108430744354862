'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen } from '@/Store/slices/favorites.slice';
import { changeRegisterData } from '@/Store/slices/loginModal.slice';
import lockIcon from '@/assets/icons/lock_icon.svg';
import appleIcon from '@/assets/icons/sign_with_apple_icon.svg';
import facebookIcon from '@/assets/icons/sign_with_facebook_icon.svg';
import googleIcon from '@/assets/icons/sign_with_google_icon.svg';
import emailIcon from '@/assets/icons/sign_with_mail_icon.svg';
import { userRegister } from '@/services/user';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { toast } from 'react-toastify';
import s from './style.module.scss';

export const Register = () => {
  const dispatch = useTDispatch();
  const t = useTranslations('');
  const { registerData } = useTSelector((state) => state.loginModal);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'phone' && e.target.value.length > 11) return;
    dispatch(changeRegisterData({ name: e.target.name, value: e.target.value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    userRegister(registerData)
      .then(() => dispatch(changeIsOpen(false)))
      .catch(() => toast.error('Տվյալները սխալ են։'));
  };

  return (
    <div className={s.register}>
      <div>
        <Image src={lockIcon} width={50} height={50} alt='lock' className={s.modalIcon} />
        <span>{t('register')}</span>
      </div>
      <form onSubmit={handleSubmit}>
        <label>
          <span>{t('name')}</span>
          <input
            required
            value={registerData.username}
            type='text'
            name='username'
            placeholder={t('name')}
            onChange={handleChange}
          />
        </label>
        <label>
          <span>{t('surname')}</span>
          <input
            required
            value={registerData.lastName}
            type='text'
            name='lastName'
            placeholder={t('surname')}
            onChange={handleChange}
          />
        </label>
        <label>
          <span>{t('email')}</span>
          <input
            required
            value={registerData.email}
            type='email'
            name='email'
            placeholder={t('email')}
            onChange={handleChange}
          />
        </label>
        <label>
          <span>{t('phone_number')}</span>
          <input
            required
            value={registerData.phone}
            type='number'
            name='phone'
            placeholder={t('phone_number')}
            onChange={handleChange}
          />
        </label>
        <button type='submit' disabled={!Object.values(registerData).every((x) => x)}>
          {t('register')}
        </button>
      </form>
      <div className={s.orSignIn}>
        <span></span>
        <h3>{t('or_register')}</h3>
        <span></span>
      </div>
      <div className={s.SignInWith}>
        <Image src={facebookIcon} width={24} height={24} alt='facebook' />
        <span>Facebook {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith}>
        <Image src={googleIcon} width={24} height={24} alt='google' />
        <span>Gmail {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith}>
        <Image src={appleIcon} width={24} height={24} alt='apple' />
        <span>Apple {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith}>
        <Image src={emailIcon} width={24} height={24} alt='email' />
        <span>Email {t('sign_in_with')}</span>
      </div>
    </div>
  );
};
