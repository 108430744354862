import { AppDispatch } from '@/Store';
import { setUser } from '@/Store/slices/global.slice';
import { getUser } from '@/services/user';

export const checkUser = (dispatch: AppDispatch) => {
  getUser()
    .then((data) => {
      if (data) {
        try {
          dispatch(setUser(data));
        } catch (e) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          dispatch(setUser(null));
        }
      }
    })
    .catch(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch(setUser(null));
    });
};
