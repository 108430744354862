'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen } from '@/Store/slices/AgentModal.slice';
import { setUser } from '@/Store/slices/global.slice';
import { changeLoginData, setIsLoginMode } from '@/Store/slices/loginModal.slice';
import eyeIcon from '@/assets/icons/eye_icon.svg';
import lockIcon from '@/assets/icons/lock_icon.svg';
import appleIcon from '@/assets/icons/sign_with_apple_icon.svg';
import facebookIcon from '@/assets/icons/sign_with_facebook_icon.svg';
import googleIcon from '@/assets/icons/sign_with_google_icon.svg';
import emailIcon from '@/assets/icons/sign_with_mail_icon.svg';
import { handleSetAuthToken } from '@/helpers/api';
import { getUser, loginRegister } from '@/services/user';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import s from './style.module.scss';

export const Login = () => {
  const dispatch = useTDispatch();
  const t = useTranslations('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const { loginData } = useTSelector((state) => state.loginModal);
  const togglePassword = () => setPasswordType(passwordType === 'password' ? 'text' : 'password');

  const handleChangeMode = () => {
    dispatch(setIsLoginMode(false));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeLoginData({ name: e.target.name, value: e.target.value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginRegister(loginData)
      .then((data) => {
        localStorage.setItem('token', data.accessToken);
        handleSetAuthToken(data.accessToken);
        getUser().then((user) => {
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(changeIsOpen(false));
          dispatch(setUser({ ...user, token: data.accessToken }));
        });
      })
      .catch(() => toast.error(t('error_invalid_username_or_password')));
  };

  const loginWithGoogle = async () => {
  };

  return (
    <div className={s.login}>
      <div>
        <Image src={lockIcon} width={50} height={50} alt='lock' className={s.modalIcon} />
        <span>{t('login_header')}</span>
      </div>
      <p>{t('enter_your_login_password')}</p>
      <form onSubmit={handleSubmit}>
        <label>
          <span>
            {t('email')}
            <b> *</b>
          </span>
          <input
            required
            value={loginData.username}
            type='email'
            name='username'
            placeholder={t('email')}
            onChange={handleChange}
          />
        </label>
        <label>
          <span>
            {t('password')}
            <b> *</b>
          </span>
          <input
            required
            value={loginData.password}
            type={passwordType}
            name='password'
            placeholder={t('password')}
            onChange={handleChange}
          />
          <button type='button' className={s.passEye} onClick={togglePassword}>
            <Image src={eyeIcon} width={18} height={14} alt='eye' />
            {passwordType === 'text' && <span>/</span>}
          </button>
        </label>
        <p className={s.forgetPass}>{t('forgot_password')}</p>
        <button type='submit'>{t('login_header')}</button>
      </form>
      <div className={s.orSignIn}>
        <span></span>
        <h3>{t('or_login')}</h3>
        <span></span>
      </div>
      <div className={s.SignInWith}>
        <Image src={facebookIcon} width={24} height={24} alt='facebook' />
        <span>Facebook {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith} onClick={loginWithGoogle}>
        <Image src={googleIcon} width={24} height={24} alt='google' />
        <span>Gmail {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith}>
        <Image src={appleIcon} width={24} height={24} alt='apple' />
        <span>Apple {t('sign_in_with')}</span>
      </div>
      <div className={s.SignInWith}>
        <Image src={emailIcon} width={24} height={24} alt='email' />
        <span>Email {t('sign_in_with')}</span>
      </div>
      <p className={s.goToRegister}>
        {t('dont_have_account')}: <b onClick={handleChangeMode}>{t('register')}</b>
      </p>
    </div>
  );
};
