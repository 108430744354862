export interface RegionsType {
  id: number;
  titleHy: string;
  titleEn: string;
  titleRu: string;
  titlePe: string;
  latitude: number;
  longitude: number;
}

export const regions: RegionsType[] = [
  {
    id: 1,
    titleHy: 'Արագածոտն',
    titleEn: 'Aragatsotn',
    titleRu: 'Арагацотн',
    titlePe: 'آراگاتسوتن',
    latitude: 40.2912662,
    longitude: 44.3229025
  },
  {
    id: 2,
    titleHy: 'Արարատ',
    titleEn: 'Ararat',
    titleRu: 'Арарат',
    titlePe: 'آرارات',
    latitude: 39.9541203,
    longitude: 44.5119874
  },
  {
    id: 3,
    titleHy: 'Արմավիր',
    titleEn: 'Armavir',
    titleRu: 'Армавир',
    titlePe: 'آرماویر',
    latitude: 40.1590191,
    longitude: 44.0250492
  },
  {
    id: 4,
    titleHy: 'Գեղարքունիք',
    titleEn: 'Gegharkunik',
    titleRu: 'Гегаркуник',
    titlePe: 'گِقارکونیک',
    latitude: 40.3545493,
    longitude: 45.119562
  },
  {
    id: 5,
    titleHy: 'Երևան',
    titleEn: 'Yerevan',
    titleRu: 'Ереван',
    titlePe: 'ایروان',
    latitude: 40.177628,
    longitude: 44.512555
  },
  {
    id: 6,
    titleHy: 'Լոռի',
    titleEn: 'Lori',
    titleRu: 'Лори',
    titlePe: 'لوری',
    latitude: 40.8159379,
    longitude: 44.4399115
  },
  {
    id: 7,
    titleHy: 'Կոտայք',
    titleEn: 'Kotayk',
    titleRu: 'Котайк',
    titlePe: 'کوتایک',
    latitude: 40.3561317,
    longitude: 44.5766239
  },
  {
    id: 8,
    titleHy: 'Շիրակ',
    titleEn: 'Shirak',
    titleRu: 'Ширак',
    titlePe: 'شیراک',
    latitude: 40.7843221,
    longitude: 43.7686485
  },
  {
    id: 9,
    titleHy: 'Սյունիք',
    titleEn: 'Syunik',
    titleRu: 'Сюник',
    titlePe: 'سیونیک',
    latitude: 39.2119091,
    longitude: 46.2231825
  },
  {
    id: 10,
    titleHy: 'Վայոց ձոր',
    titleEn: 'Vayots Dzor',
    titleRu: 'Вайоц Дзор',
    titlePe: 'وایوتس دزور',
    latitude: 39.7629985,
    longitude: 45.3160284
  },
  {
    id: 11,
    titleHy: 'Տավուշ',
    titleEn: 'Tavush',
    titleRu: 'Тавуш',
    titlePe: 'تاووش',
    latitude: 40.8895564,
    longitude: 45.1159137
  },
  {
    id: 12,
    titleHy: 'Արցախ',
    titleEn: 'Artcakh',
    titleRu: 'Арцах',
    titlePe: 'قره باغ',
    latitude: 39.751337,
    longitude: 46.235122
  }
];
