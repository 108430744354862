import telegramIcon from '@/assets/icons/telegram_icon.svg';
import viberIcon from '@/assets/icons/viber_icon.svg';
import whatsappIcon from '@/assets/icons/whatsapp_icon.svg';
import brokerImage from '@/assets/images/broker.png';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import s from './style.module.scss';

export const BrokerInfo = () => {
  const t = useTranslations('');

  return (
    <div className={s.brokerInfo}>
      <Image width={55} height={55} src={brokerImage} alt='broker' />
      <ul className={s.brokerContact}>
        <li>{t('company_name')}</li>
        <li>
          <Link href='tel:+374 44 205090'>+374 44 205090</Link>
        </li>
        <li>
          <Link href='tel:+374 77 885821'>+374 77 885821</Link>
          <Image width={16} height={18} src={viberIcon} alt='viber' />
          <Image width={18} height={18} src={whatsappIcon} alt='whatsapp' />
          <Image width={18} height={18} src={telegramIcon} alt='telegram' />
        </li>
        <li>
          <Link href='tel:+374 91 717808'>+374 91 717808</Link>
        </li>
        <li>
          <Link href='tel:+374 77 207010'>+374 77 207010</Link>
          <Image width={16} height={18} src={viberIcon} alt='viber' />
          <Image width={18} height={18} src={whatsappIcon} alt='whatsapp' />
          <Image width={18} height={18} src={telegramIcon} alt='telegram' />
        </li>
      </ul>
    </div>
  );
};
