import { usePathname, useRouter } from '@/./helpers/navigation';
import { useTDispatch } from '@/Store';
import { changeCurrency } from '@/Store/slices/global.slice';
import { MenuArrow } from '@/assets/svgComponents/menuArrow';
import { currencies, languages } from '@/helpers/utils';
import { useLocale, useTranslations } from 'next-intl';
import { useLayoutEffect, useState } from 'react';
import s from './style.module.scss';

type langSelect = (e: React.MouseEvent<HTMLElement>, label?: string, value?: string) => void;
type currencySelect = (e: React.MouseEvent<HTMLElement>, label?: string, value?: string) => void;

export const LanguageSelect = () => {
  const dispatch = useTDispatch();
  const CL = useLocale();
  const t = useTranslations('');
  const defaultLang = languages.find((item) => item.value === CL)?.label;
  const [languageSelect, setLanguageSelect] = useState({ isOpen: false, label: defaultLang });
  const [currencySelect, setCurrencySelect] = useState({ isOpen: false, label: 'USD' });
  const router = useRouter();
  const pathname = usePathname();

  const handleLanguageSelect: langSelect = (e, label, value) => {
    e.stopPropagation();
    setCurrencySelect((prev) => ({ ...prev, isOpen: false }));
    if (!label || !value) return setLanguageSelect((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    setLanguageSelect((prev) => ({ label, isOpen: !prev.isOpen }));
    router.push(pathname, { locale: value });
  };

  const handleCurrencySelect: currencySelect = (e, label, value) => {
    e.stopPropagation();
    setLanguageSelect((prev) => ({ ...prev, isOpen: false }));
    if (!label || !value) return setCurrencySelect((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    setCurrencySelect((prev) => ({ label, isOpen: !prev.isOpen }));
    localStorage.setItem('currency', value);
    dispatch(changeCurrency(value));
  };

  const handleCloseMenu = () => {
    if (!languageSelect.isOpen && !currencySelect.isOpen) return;
    setLanguageSelect((prev) => ({ ...prev, isOpen: false }));
    setCurrencySelect((prev) => ({ ...prev, isOpen: false }));
  };

  useLayoutEffect(() => {
    const curr = localStorage.getItem('currency') || 'USD';
    setCurrencySelect({ label: curr, isOpen: false });
  }, []);

  return (
    <div className={s.languageSelect} onMouseLeave={handleCloseMenu}>
      <span>
        {languageSelect.label}, {currencySelect.label} <MenuArrow />
      </span>
      <div className={s.dropDown}>
        <div>
          <span>{t('language')}</span>
          <div>
            <span className={s.languageLabel} onClick={(e) => handleLanguageSelect(e)}>
              {languageSelect.label} <MenuArrow />
            </span>
            {languageSelect.isOpen && (
              <ul>
                {languages.map((lang) => (
                  <li
                    key={lang.value}
                    onClick={(e) => handleLanguageSelect(e, lang.label, lang.value)}>
                    {lang.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div>
          <span>{t('currency')}</span>
          <div>
            <span className={s.languageLabel} onClick={(e) => handleCurrencySelect(e)}>
              {currencySelect.label} <MenuArrow />
            </span>
            {currencySelect.isOpen && (
              <ul>
                {currencies.map((curr) => (
                  <li
                    key={curr.value}
                    onClick={(e) => handleCurrencySelect(e, curr.label, curr.value)}>
                    {curr.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
