import { MenuArrow } from '@/assets/svgComponents/menuArrow';
import { Link } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import s from './style.module.scss';

export const Nav = () => {
  const t = useTranslations('');

  return (
    <nav className={s.nav}>
      <div>
        <span>
          {t('sale')} <MenuArrow />
        </span>
        <ul className={s.subMenu}>
          <li>
            <Link href='/'>{t('sale_of_apartments')}</Link>
          </li>
          <li>
            <Link href='/'>{t('sale_of_houses')}</Link>
          </li>
          <li>
            <Link href='/'>{t('sale_of_commercial')}</Link>
          </li>
          <li>
            <Link href='/'>{t('sale_of_lands')}</Link>
          </li>
        </ul>
      </div>
      <div>
        <span>
          {t('rent')} <MenuArrow />
        </span>
        <ul className={s.subMenu}>
          <li>
            <Link href='/'>{t('rent_of_apartments')}</Link>
          </li>
          <li>
            <Link href='/'>{t('rent_of_houses')}</Link>
          </li>
          <li>
            <Link href='/'>{t('rent_of_commercial')}</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
