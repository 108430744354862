'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen, changeMessageData } from '@/Store/slices/AgentModal.slice';
import { BrokerInfo } from '@/app/[locale]/property/[id]/bottomBlock/brokerInfo';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import s from './style.module.scss';

export const AgentRequestModal = () => {
  const dispatch = useTDispatch();
  const { id } = useParams<Record<string, string>>();

  const t = useTranslations();
  const { isOpen, data } = useTSelector((state) => state.agentModal);
  const { name, phone, message, email } = data;
  const handleCloseModal = () => {
    dispatch(changeIsOpen(false));
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dispatch(changeMessageData({ name, value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      {isOpen && (
        <div className={s.modal} onClick={handleCloseModal}>
          <div className={s.container} onClick={(e) => e.stopPropagation()}>
            <h2 className={s.title}>{t('ask_agent')}</h2>
            <form onSubmit={handleSubmit}>
              <input
                name='name'
                value={name}
                required
                type='text'
                onChange={handleFormChange}
                placeholder={t('name') + ' ' + t('surname')}
              />
              <input
                name='phone'
                value={phone}
                required
                type='text'
                onChange={handleFormChange}
                placeholder={t('phone_number')}
              />
              <input
                name='email'
                value={email}
                required
                type='email'
                onChange={handleFormChange}
                placeholder={t('email')}
              />
              <textarea
                value={message}
                name='message'
                required
                onChange={handleFormChange}
                placeholder={t('send_request_default_text', { id })}
              />
              <button type='submit'>{t('send')}</button>
            </form>
            <div className={s.bottom}>
              <BrokerInfo />
            </div>
            <button className={s.close} onClick={handleCloseModal}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};
