import { api } from '@/helpers/api';

interface UserLoginType {
  username: string;
  password: string;
}

interface UserRegisterType {
  username: string;
  lastName: string;
  email: string;
  phone: string;
}

export const userRegister = async (data: UserRegisterType) => {
  return await api('user/signup', 'POST', data);
};

export const loginRegister = async (data: UserLoginType) => {
  const result = await api('auth/login', 'POST', data);
  return result;
};

export const getUser = async () => {
  const result = await api('user/details', 'GET');
  return result;
};
