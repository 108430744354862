import { Form } from '@/Components/form';
import { useTranslations } from 'next-intl';
import s from './style.module.scss';

export const AboutUs = () => {
  const t = useTranslations('');

  return (
    <div className={s.aboutUs}>
      <div>
        <div className={s.info}>
          <h3>{t('about_us')}</h3>
          <p>{t('about_us_text')}</p>
        </div>
        <Form />
      </div>
    </div>
  );
};
