import { MenuArrow } from '@/assets/svgComponents/menuArrow';
import { Link } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { isOpenType } from './actions';
import { filterData } from './filterData';
import s from './style.module.scss';
import { Filter7Type, FilterKeyValueType, buildType } from './types';

// interface Filter7Props {
//   searchParams: string;
//   filter7: Filter7Type;
//   setFilter7: React.Dispatch<React.SetStateAction<Filter7Type>>;
//   subMenuIsOpen: string;
//   handleOpenSubMenu: (e: React.MouseEvent, menu: string) => void;
// }

export const Filter7 = ({
  searchParams,
  rendered,
  filter,
  filter7,
  setFilter7,
  subMenuIsOpen,
  handleOpenSubMenu
}: any) => {
  const t = useTranslations('');
  const [isOpen, setIsOpen] = useState<isOpenType>({ minFloor: false, maxFloor: false });

  const handleAdditionalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'isNewConstruction' || name === 'isOnlyWithImages') {
      setFilter7((prev: any) => ({ ...prev, isNewConstruction: !prev.isNewConstruction }));
    } else {
      setFilter7((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeMinMax = (key: string, value: string) => {
    setIsOpen((prev) => ({ ...prev, [key]: false }));
    setFilter7((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleOpenMenu = (key: string) => {
    setIsOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCloseMenu = () => {
    if (!isOpen.minFloor && !isOpen.maxFloor) return;
    setIsOpen((prev) => ({ ...prev, minFloor: false, maxFloor: false }));
  };

  const handleChangeLabelValueArr = (key: keyof Filter7Type, label: string, value: string) => {
    if (filter7[key].some((item: FilterKeyValueType) => item.label === label)) {
      setFilter7((prev: any) => ({
        ...prev,
        [key]: prev[key].filter((item: buildType) => item.label !== label)
      }));
    } else {
      return setFilter7((prev: any) => ({ ...prev, [key]: [...prev[key], { label, value }] }));
    }
  };

  const handleSaveFilters = () => {
    localStorage.setItem('filter', JSON.stringify(filter));
    localStorage.setItem('filter7', JSON.stringify(filter7));
  };

  return (
    <li
      className={`${s.button} ${subMenuIsOpen === 'filter7'}`}
      onClick={(e) => handleOpenSubMenu(e, 'filter7')}>
      <span className={s.title}>
        <span>{t('more_filters')}</span>
        <MenuArrow />
      </span>
      {rendered && (
        <form className={s.filter7} onClick={(e) => e.stopPropagation()}>
          <div className={s.additional}>
            <h3>{t('additional')}</h3>
            <input
              type='text'
              autoComplete='one-time-code'
              placeholder={t('more_filter_property_id')}
              name='id'
              value={filter7.id}
              onChange={handleAdditionalChange}
            />
            <input
              type='text'
              autoComplete='one-time-code'
              placeholder={t('address')}
              name='address'
              value={filter7.address}
              onChange={handleAdditionalChange}
            />
            <label>
              <input
                type='checkbox'
                name='isNewConstruction'
                defaultChecked={filter7.isNewConstruction}
                onChange={handleAdditionalChange}
              />
              <span>{t('new_construction')}</span>
            </label>
            <label>
              <input
                type='checkbox'
                name='isOnlyWithImages'
                defaultChecked={filter7.isOnlyWithImages}
                onChange={handleAdditionalChange}
              />
              <span>{t('with_pics')}</span>
            </label>
          </div>

          <div className={s.area} onMouseLeave={handleCloseMenu}>
            <h3 className={s.label}>{t('floor')}</h3>
            <div className={s.floorBlock}>
              <span className={s.floorTitle} onClick={() => handleOpenMenu('minFloor')}>
                <span>{filter7.minFloor.toLocaleString() || t('min_short')}</span>
                <MenuArrow />
              </span>
              <span className={s.floorTitle} onClick={() => handleOpenMenu('maxFloor')}>
                <span>{filter7.maxFloor.toLocaleString() || t('max_short')}</span>
                <MenuArrow />
              </span>
              {isOpen.minFloor && (
                <ul className={s.floorMenu1}>
                  <li onClick={() => handleChangeMinMax('minFloor', 'any')}>{t('any')}</li>
                  <li onClick={() => handleChangeMinMax('minFloor', 'basement')}>
                    {t('basement')}
                  </li>
                  <li onClick={() => handleChangeMinMax('minFloor', 'semi_basement')}>
                    {t('semi_basement')}
                  </li>
                  {filterData.filter7.floor.map((item) => (
                    <li key={item} onClick={() => handleChangeMinMax('minFloor', item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
              {isOpen.maxFloor && (
                <ul className={s.floorMenu2}>
                  <li onClick={() => handleChangeMinMax('maxFloor', 'any')}>{t('any')}</li>
                  <li onClick={() => handleChangeMinMax('maxFloor', 'basement')}>
                    {t('basement')}
                  </li>
                  <li onClick={() => handleChangeMinMax('maxFloor', 'semi_basement')}>
                    {t('semi_basement')}
                  </li>
                  {filterData.filter7.floor.map((item) => (
                    <li key={item} onClick={() => handleChangeMinMax('maxFloor', item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className={s.minTotalFloorMax}>
            <h3>
              {t('floor_quantity')} ({t('min_short')}-{t('max_short')})
            </h3>
            <input
              type='number'
              autoComplete='one-time-code'
              placeholder={t('min_short')}
              name='minTotalFloor'
              value={filter7.minTotalFloor}
              onChange={handleAdditionalChange}
            />
            <input
              type='number'
              autoComplete='one-time-code'
              placeholder={t('max_short')}
              name='maxTotalFloor'
              value={filter7.maxTotalFloor}
              onChange={handleAdditionalChange}
            />
          </div>

          <div className={s.minTotalFloorMax}>
            <h3>{t('all_area')}</h3>
            <input
              type='number'
              autoComplete='one-time-code'
              placeholder={t('min_short')}
              name='minTotalArea'
              value={filter7.minTotalArea}
              onChange={handleAdditionalChange}
            />
            <input
              type='number'
              autoComplete='one-time-code'
              placeholder={t('max_short')}
              name='maxTotalArea'
              value={filter7.maxTotalArea}
              onChange={handleAdditionalChange}
            />
          </div>

          <div className={s.buildType}>
            <h3>{t('building_type_title')}</h3>
            {filterData.filter7.buildType.map(({ label, value }) => (
              <label
                key={label}
                onClick={() => handleChangeLabelValueArr('buildType', label, value)}>
                <input type='checkbox' onClick={(e) => e.stopPropagation()} />
                <span>{t(label)}</span>
              </label>
            ))}
          </div>

          <div className={s.status}>
            <h3>{t('status')}</h3>
            {filterData.filter7.status.map(({ label, value }) => (
              <label key={label} onClick={() => handleChangeLabelValueArr('status', label, value)}>
                <input type='checkbox' onClick={(e) => e.stopPropagation()} />
                <span>{t(label)}</span>
              </label>
            ))}
          </div>

          <Link
            onClick={handleSaveFilters}
            href={`/property-filter?${searchParams}`}
            title='search'
            className={s.additionalSubmit}>
            {t('search')}
          </Link>
        </form>
      )}
    </li>
  );
};
