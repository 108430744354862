'use client';
import { AboutUs } from '@/Components/Home/AboutUs';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen } from '@/Store/slices/loginModal.slice';
import headerLogo from '@/assets/icons/header_logo.svg';
import headerLogoDark from '@/assets/icons/header_logo_dark.svg';
import Phone_logo from '@/assets/icons/phone_icon.svg';
import Viber_logo from '@/assets/icons/viber_icon.svg';
import Whatsapp_logo from '@/assets/icons/whatsapp_icon.svg';
import { FacebookIcon } from '@/assets/svgComponents/facebookIcon';
import { InstagramIcon } from '@/assets/svgComponents/instagramIcon';
import { TelegramIcon } from '@/assets/svgComponents/telegramIcon';
import { TikTokIcon } from '@/assets/svgComponents/tikTokIcon';
import { YoutubeIcon } from '@/assets/svgComponents/youtubeIcon';
import { Link } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import s from './style.module.scss';

export const Footer = ({ hideAbout }: { hideAbout?: boolean }) => {
  const dispatch = useTDispatch();
  const t = useTranslations('');
  const { darkMode } = useTSelector((state) => state.global);

  const handleLoginModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(changeIsOpen(true));
  };

  return (
    <footer>
      {!hideAbout && <AboutUs />}
      <div className={s.footerNav}>
        <div>
          <Image
            src={darkMode === 'dark' ? headerLogoDark : headerLogo}
            alt='logo'
            width={125}
            height={31}
          />
          <div className={s.footerMenu}>
            <ul>
              <li>{t('menu')}</li>
              <li>
                <Link href='/contact-us'>{t('contact')}</Link>
              </li>
              <li>
                <Link href='/blog'>{t('blog')}</Link>
              </li>
              <li>
                <Link href='/about-us'>{t('about_us')}</Link>
              </li>
              <li>
                <Link href='/' onClick={handleLoginModal}>
                  {t('my_favorites')}
                </Link>
              </li>
              <li>
                <Link href='/'>{t('news')}</Link>
              </li>
            </ul>
            <ul>
              <li>{t('contacts')}</li>
              <li className={s.phoneNumbers}>
                <b>{t('phone_number_short')}:</b>
                <div>
                  <Link href='tel: +374 55 404090'>+374 55 404090</Link>
                  <Link href='tel: +374 98 204054'>+374 98 204054</Link>
                </div>
                <Link href='mailto: ararat@505.am'>
                  <b>{t('email')}:</b>ararat@505.am
                </Link>
                <Link
                  href='https://yandex.com/maps/10262/yerevan/house/YE0YcwZiSUAFQFpqfX15dn5rZQ==/?ll=44.512052%2C40.187282&z=17.19'
                  target='_blank'>
                  {t('company_address')}
                </Link>
              </li>
              <li className={s.contactUs}>
                <h3>{t('complaints_suggestions')}</h3>
                <div>
                  <Image loading='lazy' src={Phone_logo} width={20} height={20} alt='phone' />
                  <Image loading='lazy' src={Viber_logo} width={20} height={20} alt='viber' />
                  <Image loading='lazy' src={Whatsapp_logo} width={20} height={20} alt='whatsapp' />
                </div>
                <Link href='tel: +374 77 207010'>+374 77 207010</Link>
              </li>
            </ul>
            <ul className={s.socials}>
              <li>
                <Link
                  href='https://www.instagram.com/ararat_realty/'
                  title='Instagram'
                  target='_blank'>
                  <InstagramIcon />
                  <span>Instagram</span>
                </Link>
              </li>
              <li>
                <Link
                  href='https://www.facebook.com/araratrealtybnakaranyerevanrentbuyapartment'
                  title='Facebook'
                  target='_blank'>
                  <FacebookIcon />
                  <span>Facebook</span>
                </Link>
              </li>
              <li>
                <Link
                  href='https://www.youtube.com/channel/UCdNAv0KyzT9-tfjDxFbO3Fw'
                  title='Youtube'
                  target='_blank'>
                  <YoutubeIcon />
                  <span>Youtube</span>
                </Link>
              </li>
              <li>
                <Link
                  href='https://web.telegram.org/a/#-1001989567785'
                  title='Telegram'
                  target='_blank'>
                  <TelegramIcon />
                  <span>Telegram</span>
                </Link>
              </li>
              <li>
                <Link href='https://www.tiktok.com/@ararat.realty' title='TikTok' target='_blank'>
                  <TikTokIcon />
                  <span>TikTok</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={s.footerBottom}>
        <div>
          <span>Ararat Realty Real Estate Agency © {new Date().getFullYear()}</span>
          <Link href='/'>Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};
