import { useTDispatch, useTSelector } from '@/Store';
import { setUser } from '@/Store/slices/global.slice';
import { FavoriteIcon } from '@/assets/svgComponents/favoriteIcon';
import { LogoutIcon } from '@/assets/svgComponents/logoutIcon';
import { UserIcon } from '@/assets/svgComponents/userIcon';
import { Link } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import s from './style.module.scss';

export const User = () => {
  const t = useTranslations();
  const dispatch = useTDispatch();
  const { user } = useTSelector((state) => state.global);
  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(setUser(null));
  };

  useEffect(() => {
    window.addEventListener('click', handleCloseMenu);
    return () => window.removeEventListener('click', handleCloseMenu);
  }, []);

  return (
    <>
      {user && (
        <div className={s.userBlock} onClick={handleToggleMenu}>
          <UserIcon />
          <span>{user.firstName + ' ' + (user.lastName?.[0] || '')}</span>
          {openMenu && (
            <ul>
              <li>
                <Link href='/favorites'>
                  <FavoriteIcon fill='#98a2b3' />
                  <span>{t('favorites_dropdown')}</span>
                </Link>
              </li>
              <li onClick={handleLogout}>
                <LogoutIcon />
                <span>{t('logout_dropdown')}</span>
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};
