interface Props {
  fill?: string;
}

export const MenuArrow = ({ fill }: Props) => {
  return (
    <svg width='8' height='6' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 7.5374L0 1.5374L1.075 0.462402L6 5.4124L10.925 0.487402L12 1.5624L6 7.5374Z'
        fill={fill || '#000'}
      />
    </svg>
  );
};
