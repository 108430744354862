'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeDarkMode } from '@/Store/slices/global.slice';
import { changeIsOpen } from '@/Store/slices/loginModal.slice';
import headerLogo from '@/assets/icons/header_logo.svg';
import headerLogoDark from '@/assets/icons/header_logo_dark.svg';
import { PhoneIcon } from '@/assets/svgComponents/phoneIcon';
import { handleSetAuthToken } from '@/helpers/api';
import { checkUser } from '@/helpers/checkUser';
import { Link, usePathname } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect } from 'react';
import { LanguageSelect } from './languageSelect';
import { Menu } from './menu';
import { Nav } from './nav';
import s from './style.module.scss';
import { User } from './user';

export const Header = () => {
  const dispatch = useTDispatch();
  const { darkMode, user } = useTSelector((state) => state.global);
  const t = useTranslations('');
  const pathname = usePathname();

  const handleLoginModal = (e: React.SyntheticEvent) => {
    if (!user) {
      e.preventDefault();
      dispatch(changeIsOpen(true));
    }
  };

  useEffect(() => {
    dispatch(changeDarkMode(localStorage.getItem('theme') || 'light'));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    handleSetAuthToken();
    checkUser(dispatch);
  }, [pathname]);

  return (
    <header>
      <div>
        <Link href='/'>
          <Image
            src={darkMode === 'dark' ? headerLogoDark : headerLogo}
            alt='logo'
            width={125}
            height={31}
          />
        </Link>
        <Nav />
        <div className={s.rightBlock}>
          <Link href='tel:+37444205090' className={s.phone}>
            <PhoneIcon />
            <span>+(374) 44 205090</span>
          </Link>
          <LanguageSelect />
          <Link href='/submit-listing' className={s.add} onClick={handleLoginModal}>
            <b>+</b>
            <span>{t('add')}</span>
          </Link>
          <User />
          <Menu handleLoginModal={handleLoginModal} />
        </div>
      </div>
    </header>
  );
};
