'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen, setIsLoginMode } from '@/Store/slices/loginModal.slice';
import { Login } from './Login';
import { Register } from './Register';
import s from './style.module.scss';

export const LoginModal = () => {
  const dispatch = useTDispatch();
  const { isOpen, isLoginMode } = useTSelector((state) => state.loginModal);
  const { user } = useTSelector((state) => state.global);

  const handleCloseModal = () => {
    dispatch(changeIsOpen(false));
    dispatch(setIsLoginMode(true));
  };

  if (user) return;

  return (
    <>
      {isOpen && (
        <div className={s.loginModal} onClick={handleCloseModal}>
          <div className={s.container} onClick={(e) => e.stopPropagation()}>
            {isLoginMode && <Login />}
            {!isLoginMode && <Register />}
            <button className={s.close} onClick={handleCloseModal}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};
