interface Utility {
  title: string;
  utilityType: string;
  id: number;
}

export const utils: Utility[] = [
  {
    title: 'Դուպլեքս',
    utilityType: 'TAG',
    id: 1
  },
  {
    title: 'penthouse',
    utilityType: 'TAG',
    id: 2
  },
  {
    title: 'townhouse',
    utilityType: 'TAG',
    id: 3
  },
  {
    title: 'Մոդեռն',
    utilityType: 'TAG',
    id: 4
  },
  {
    title: 'բարձրառաջինհարկ',
    utilityType: 'TAG',
    id: 5
  },
  {
    title: 'շքամուտք',
    utilityType: 'TAG',
    id: 6
  },
  {
    title: 'Ստալինկա',
    utilityType: 'TAG',
    id: 7
  },
  {
    title: 'բացպատշգամբ',
    utilityType: 'TAG',
    id: 8
  },
  {
    title: 'փոքրկենտրոն',
    utilityType: 'TAG',
    id: 9
  },
  {
    title: 'մեծկենտրոն',
    utilityType: 'TAG',
    id: 10
  },
  {
    title: 'զրոյականմուտք',
    utilityType: 'TAG',
    id: 11
  },
  {
    title: 'կիսանկուղ',
    utilityType: 'TAG',
    id: 12
  },
  {
    title: 'Չվերանորոգված',
    utilityType: 'TAG',
    id: 13
  },
  {
    title: 'Առանձինխոհանոց',
    utilityType: 'TAG',
    id: 14
  },
  {
    title: 'որպեսբիզնես',
    utilityType: 'TAG',
    id: 15
  },
  {
    title: 'վարձովհանձնված',
    utilityType: 'TAG',
    id: 16
  },
  {
    title: 'շտապվաճառք',
    utilityType: 'TAG',
    id: 17
  },
  {
    title: 'էժան',
    utilityType: 'TAG',
    id: 18
  },
  {
    title: 'արարատիտեսարան',
    utilityType: 'TAG',
    id: 19
  },
  {
    title: 'չբնակեցված',
    utilityType: 'TAG',
    id: 20
  },
  {
    title: 'կահույքևտեխնիկա',
    utilityType: 'TAG',
    id: 21
  },
  {
    title: '2%պայմանագրով',
    utilityType: 'TAG',
    id: 22
  },
  {
    title: 'fullexclusive',
    utilityType: 'TAG',
    id: 23
  },
  {
    title: 'կասկադ',
    utilityType: 'TAG',
    id: 24
  },
  {
    title: 'անտառային',
    utilityType: 'TAG',
    id: 25
  },
  {
    title: 'փարկինգ',
    utilityType: 'TAG',
    id: 26
  },
  {
    title: 'ավտոտնակ',
    utilityType: 'TAG',
    id: 27
  },
  {
    title: 'վերելակ',
    utilityType: 'TAG',
    id: 28
  },
  {
    title: 'ձեղնահարկ',
    utilityType: 'TAG',
    id: 29
  },
  {
    title: 'ներկառուցված',
    utilityType: 'TAG',
    id: 30
  },
  {
    title: 'պանելային',
    utilityType: 'TAG',
    id: 31
  },
  {
    title: 'չեխական',
    utilityType: 'TAG',
    id: 32
  },
  {
    title: 'մոսկովյանդեսկա',
    utilityType: 'TAG',
    id: 33
  },
  {
    title: 'վիտրաժ ',
    utilityType: 'TAG',
    id: 34
  },
  {
    title: 'շքապատշգամբ ',
    utilityType: 'TAG',
    id: 35
  },
  {
    title: 'առաջնայինշուկա',
    utilityType: 'TAG',
    id: 36
  },
  {
    title: 'բադալյան',
    utilityType: 'TAG',
    id: 37
  },
  {
    title: 'բետոնեծածկ',
    utilityType: 'TAG',
    id: 38
  },
  {
    title: 'նորակառույց',
    utilityType: 'TAG',
    id: 39
  },
  {
    title: 'կոմերցիոն ',
    utilityType: 'TAG',
    id: 40
  },
  {
    title: 'վրացական ',
    utilityType: 'TAG',
    id: 41
  },
  {
    title: 'մանսարդ ',
    utilityType: 'TAG',
    id: 42
  },
  {
    title: 'ավելացրածհարկ',
    utilityType: 'TAG',
    id: 43
  },
  {
    title: 'շունուկատու',
    utilityType: 'TAG',
    id: 44
  },
  {
    title: 'նիշա ',
    utilityType: 'TAG',
    id: 45
  },

  {
    title: 'երկուսըերեքդարձրած ',
    utilityType: 'TAG',
    id: 46
  },
  {
    title: 'ավելացրածմաս',
    utilityType: 'TAG',
    id: 47
  },
  {
    title: 'վերջինհարկ',
    utilityType: 'TAG',
    id: 48
  },
  {
    title: 'անհատականջեռուցում',
    utilityType: 'TAG',
    id: 49
  },
  {
    title: 'կենտրոնացվածջեռուցում ',
    utilityType: 'TAG',
    id: 50
  },
  {
    title: 'երկկողմանիդիրք ',
    utilityType: 'TAG',
    id: 51
  },
  {
    title: 'հարավայինդիրք ',
    utilityType: 'TAG',
    id: 52
  },
  {
    title: 'անկյունայինդիրք ',
    utilityType: 'TAG',
    id: 53
  },
  {
    title: 'կարճաժամկետ ',
    utilityType: 'TAG',
    id: 54
  },
  {
    title: 'լողավազան ',
    utilityType: 'TAG',
    id: 55
  },
  {
    title: 'առանձինշինություն ',
    utilityType: 'TAG',
    id: 56
  },
  {
    title: 'մեծհող ',
    utilityType: 'TAG',
    id: 57
  },
  {
    title: 'փոքրառանձնատուն ',
    utilityType: 'TAG',
    id: 58
  },
  {
    title: 'ամառանոց ',
    utilityType: 'TAG',
    id: 59
  },
  {
    title: 'տեռաս',
    utilityType: 'TAG',
    id: 60
  },
  {
    title: 'օֆիսայինտարածք ',
    utilityType: 'TAG',
    id: 61
  },
  {
    title: 'մալիբու ',
    utilityType: 'TAG',
    id: 62
  },
  {
    title: 'վահագնի ',
    utilityType: 'TAG',
    id: 63
  },
  {
    title: 'հրապարակինմոտ ',
    utilityType: 'TAG',
    id: 64
  },
  {
    title: 'ցածրնորակառույց ',
    utilityType: 'TAG',
    id: 65
  },
  {
    title: 'մոնումենտ ',
    utilityType: 'TAG',
    id: 66
  },
  {
    title: 'ռայկոմ',
    utilityType: 'TAG',
    id: 67
  },
  {
    title: 'երկրորդգիծ ',
    utilityType: 'TAG',
    id: 68
  },
  {
    title: 'ասֆալտապատ ',
    utilityType: 'TAG',
    id: 69
  },
  {
    title: 'ճանապարհամերձ ',
    utilityType: 'TAG',
    id: 70
  },
  {
    title: 'չբնակեցված ',
    utilityType: 'TAG',
    id: 71
  },
  {
    title: 'բարձրառաստաղ ',
    utilityType: 'TAG',
    id: 72
  },
  {
    title: 'բժշկականինմոտ ',
    utilityType: 'TAG',
    id: 73
  },
  {
    title: 'վերնիսաժ ',
    utilityType: 'TAG',
    id: 74
  },
  {
    title: 'փակշուկա ',
    utilityType: 'TAG',
    id: 75
  },
  {
    title: 'պրոսպեկտ ',
    utilityType: 'TAG',
    id: 76
  },
  {
    title: 'շուտհանձնվողշենք ',
    utilityType: 'TAG',
    id: 77
  },
  {
    title: 'եկամտահարկով ',
    utilityType: 'TAG',
    id: 78
  },
  {
    title: 'հյուսիսայիննայող ',
    utilityType: 'TAG',
    id: 79
  },
  {
    title: 'այգինայող',
    utilityType: 'TAG',
    id: 80
  },
  {
    title: 'մետրոյիմոտ ',
    utilityType: 'TAG',
    id: 81
  },
  {
    title: 'տեսարան ',
    utilityType: 'TAG',
    id: 82
  },
  {
    title: 'բալկոն ',
    utilityType: 'TAG',
    id: 83
  },
  {
    title: 'ստուգվածփաստաթղթեր ',
    utilityType: 'TAG',
    id: 84
  },
  {
    title: 'դալմայիմոտ ',
    utilityType: 'TAG',
    id: 85
  },
  {
    title: 'առանցկահույք ',
    utilityType: 'TAG',
    id: 86
  },
  {
    title: 'ստուդիո ',
    utilityType: 'TAG',
    id: 87
  },
  {
    title: 'հատուկնախագիծ ',
    utilityType: 'TAG',
    id: 88
  },
  {
    title: 'կոմիտասիսկիզբ',
    utilityType: 'TAG',

    id: 89
  },
  {
    title: 'heating',
    utilityType: 'PRIMARY',
    id: 90
  },
  {
    title: 'gas',
    utilityType: 'PRIMARY',
    id: 91
  },
  {
    title: 'hot_water',
    utilityType: 'PRIMARY',
    id: 92
  },
  {
    title: 'internet',
    utilityType: 'PRIMARY',
    id: 93
  },
  {
    title: 'air_conditioner',
    utilityType: 'PRIMARY',
    id: 94
  },
  {
    title: 'centralized_heating',
    utilityType: 'PRIMARY',
    id: 95
  },
  {
    title: 'electricity',
    utilityType: 'PRIMARY',
    id: 96
  },
  {
    title: 'permanent_water',
    utilityType: 'PRIMARY',
    id: 97
  },
  {
    title: 'drinking_water',
    utilityType: 'PRIMARY',
    id: 98
  },
  {
    title: 'irrigation',
    utilityType: 'PRIMARY',
    id: 99
  },
  {
    title: 'sewerage',
    utilityType: 'PRIMARY',
    id: 100
  },
  {
    title: 'furniture',
    utilityType: 'ADDITIONAL',
    id: 101
  },
  {
    title: 'technics',
    utilityType: 'ADDITIONAL',
    id: 102
  },
  {
    title: 'open_balcony',
    utilityType: 'ADDITIONAL',
    id: 103
  },
  {
    title: 'closed_balcony',
    utilityType: 'ADDITIONAL',
    id: 104
  },
  {
    title: 'loggia',
    utilityType: 'ADDITIONAL',
    id: 105
  },
  {
    title: 'lift',
    utilityType: 'ADDITIONAL',
    id: 106
  },
  {
    title: 'basement',
    utilityType: 'ADDITIONAL',
    id: 107
  },
  {
    title: 'high_first_floor',
    utilityType: 'ADDITIONAL',
    id: 108
  },
  {
    title: 'attic',
    utilityType: 'ADDITIONAL',
    id: 109
  },
  {
    title: 'storage_room',
    utilityType: 'ADDITIONAL',
    id: 110
  },
  {
    title: 'europe_window',
    utilityType: 'ADDITIONAL',
    id: 111
  },
  {
    title: 'tile',
    utilityType: 'ADDITIONAL',
    id: 112
  },
  {
    title: 'heated_floor',
    utilityType: 'ADDITIONAL',
    id: 113
  },
  {
    title: 'laminate',
    utilityType: 'ADDITIONAL',
    id: 114
  },
  {
    title: 'parquet_floor',
    utilityType: 'ADDITIONAL',
    id: 115
  },
  {
    title: 'sunny',
    utilityType: 'ADDITIONAL',
    id: 116
  },
  {
    title: 'beautiful_view',
    utilityType: 'ADDITIONAL',
    id: 117
  },
  {
    title: 'near_stop',
    utilityType: 'ADDITIONAL',
    id: 118
  },
  {
    title: 'roadside',
    utilityType: 'ADDITIONAL',
    id: 119
  },
  {
    title: 'park',
    utilityType: 'ADDITIONAL',
    id: 120
  },
  {
    title: 'playground',
    utilityType: 'ADDITIONAL',
    id: 121
  },
  {
    title: 'garage',
    utilityType: 'ADDITIONAL',
    id: 122
  },
  {
    title: 'car_park',
    utilityType: 'ADDITIONAL',
    id: 123
  },
  {
    title: 'security_system',
    utilityType: 'ADDITIONAL',
    id: 124
  },
  {
    title: 'grids',
    utilityType: 'ADDITIONAL',
    id: 125
  },
  {
    title: 'turbo_stove',
    utilityType: 'ADDITIONAL',
    id: 126
  },
  {
    title: 'fireplace',
    utilityType: 'ADDITIONAL',
    id: 127
  },
  {
    title: 'availability_construction',
    utilityType: 'ADDITIONAL',
    id: 128
  },
  {
    title: 'gate',
    utilityType: 'ADDITIONAL',
    id: 129
  },
  {
    title: 'walled',
    utilityType: 'ADDITIONAL',
    id: 130
  },
  {
    title: 'double_sided',

    utilityType: 'ADDITIONAL',
    id: 131
  },
  {
    title: 'iron_door',
    utilityType: 'ADDITIONAL',
    id: 132
  },
  {
    title: 'swimming_pool',
    utilityType: 'ADDITIONAL',
    id: 133
  },
  {
    title: 'gym',
    utilityType: 'ADDITIONAL',
    id: 134
  },
  {
    title: 'sauna',
    utilityType: 'ADDITIONAL',
    id: 135
  }
];

export const langKey: Record<string, { title: string; description: string }> = {
  hy: {
    title: 'titleHy',
    description: 'descriptionHy'
  },
  ru: {
    title: 'titleRu',
    description: 'descriptionRu'
  },
  en: {
    title: 'titleEn',
    description: 'descriptionEn'
  },
  pe: {
    title: 'titleEn',
    description: 'descriptionEn'
  }
};

export const currencyKey: Record<string, string> = {
  USD: 'priceUsd',
  AMD: 'priceAmd',
  EUR: 'priceEur',
  RUB: 'priceRub'
};

export const curr_symbols = {
  USD: '$', // US Dollar
  RUB: '₽', // Russian Ruble
  AMD: '֏', // Armenian Dram
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫' // Vietnamese Dong
};

export const languages = [
  { label: 'ՀԱՅ', value: 'hy' },
  { label: 'ENG', value: 'en' },
  { label: 'РУС', value: 'ru' }
  // { label: 'فارسی', value: 'pe' }
];

export const currencies = [
  { label: 'USD', value: 'USD' },
  { label: 'AMD', value: 'AMD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'RUB', value: 'RUB' }
];

export const floors = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25+'
];

export const rooms = ['1', '2', '3', '4', '5', '6', '7+'];

export const bathrooms = ['1', '2', '3', '4', '5+'];

export const buildingTypes = ['monolith', 'stone', 'panel', 'other'];

export const ceil_heights = ['2.6 M', '2.8 M', '3.0 M', '3.2 M', '3.8 M', '5 M+'];

export const companyAddress = {
  latitude: 40.186951,
  longitude: 44.512106,
  zoomLevel: 14
};
