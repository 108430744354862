export interface DistrictsType {
  id: number;
  titleHy: string;
  titleEn: string;
  titleRu: string;
  titlePe: string;
  regionId: number;
}

export const districts: DistrictsType[] = [
  {
    id: 64,
    titleHy: 'Կենտրոն',
    titleEn: 'Center',
    titleRu: 'Центр',
    titlePe: 'مرکز شهر',
    regionId: 5
  },
  {
    id: 60,
    titleHy: 'Արաբկիր',
    titleEn: 'Arabkir',
    titleRu: 'Арабкир',
    titlePe: 'عربگیر',
    regionId: 5
  },
  {
    id: 62,
    titleHy: 'Դավթաշեն',
    titleEn: 'Davtashen',
    titleRu: 'Давиташен',
    titlePe: 'داوتاشِن',
    regionId: 5
  },
  {
    id: 61,
    titleHy: 'Աջափնյակ',
    titleEn: 'Achapnyak',
    titleRu: 'Ачапняк',
    titlePe: 'آچاپنیاک',
    regionId: 5
  },
  {
    id: 73,
    titleHy: 'Վահագնի թաղ',
    titleEn: 'Vahagni district',
    titleRu: 'Ваагни район',
    titlePe: 'Vahagni district5',
    regionId: 5
  },
  {
    id: 69,
    titleHy: 'Քանաքեռ-Զեյթուն',
    titleEn: 'Qanaqer-Zeytun',
    titleRu: 'Канакер-Зейтун',
    titlePe: 'کاناکِر – زیتون',
    regionId: 5
  },
  { id: 58, titleHy: 'Ավան', titleEn: 'Avan', titleRu: 'Аван', titlePe: 'آوان', regionId: 5 },
  {
    id: 66,
    titleHy: 'Նոր Նորք',
    titleEn: 'Nor Norq',
    titleRu: 'Нор Норк',
    titlePe: 'نور نورک',
    regionId: 5
  },
  {
    id: 67,
    titleHy: 'Նորք Մարաշ',
    titleEn: 'Norq Marash',
    titleRu: 'Норк Мараш',
    titlePe: 'نورک ماراش',
    regionId: 5
  },
  {
    id: 65,
    titleHy: 'Մալաթիա-Սեբաստիա',
    titleEn: 'Malatia-Sebastia',
    titleRu: 'Малатиа-Себастиа',
    titlePe: 'مالاتیا - سِباستیا',
    regionId: 5
  },
  {
    id: 63,
    titleHy: 'Էրեբունի',
    titleEn: 'Erebuni',
    titleRu: 'Эребуни',
    titlePe: 'اِرِبونی',
    regionId: 5
  },
  {
    id: 59,
    titleHy: 'Շենգավիթ',
    titleEn: 'Shengavit',
    titleRu: 'Шенгавит',
    titlePe: 'شِنگاویت',
    regionId: 5
  },
  {
    id: 68,
    titleHy: 'Նուբարաշեն',
    titleEn: 'Nubarashen',
    titleRu: 'Нубарашен',
    titlePe: 'نوباراشِن',
    regionId: 5
  },
  {
    id: 1,
    titleHy: 'Աբովյան',
    titleEn: 'Abovyan',
    titleRu: 'Абовян',
    titlePe: 'اَبوویان',
    regionId: 7
  },
  {
    id: 2,
    titleHy: 'Ալավերդի',
    titleEn: 'Alaverdi',
    titleRu: 'Алаверди',
    titlePe: 'آلاوِردی',
    regionId: 6
  },
  {
    id: 3,
    titleHy: 'Ամասիա',
    titleEn: 'Amasia',
    titleRu: 'Амасиа',
    titlePe: 'آماسیا',
    regionId: 8
  },
  {
    id: 4,
    titleHy: 'Ապարան',
    titleEn: 'Aparan',
    titleRu: 'Апаран',
    titlePe: 'آپاران',
    regionId: 1
  },
  {
    id: 5,
    titleHy: 'Արագած',
    titleEn: 'Aragats',
    titleRu: 'Арагац',
    titlePe: 'آراگاتس',
    regionId: 8
  },
  {
    id: 6,
    titleHy: 'Արարատ',
    titleEn: 'Ararat',
    titleRu: 'Арарат',
    titlePe: 'آرارات',
    regionId: 2
  },
  {
    id: 7,
    titleHy: 'Արմավիր',
    titleEn: 'Armavir',
    titleRu: 'Армавир',
    titlePe: 'آرماویر',
    regionId: 3
  },
  {
    id: 8,
    titleHy: 'Արտաշատ',
    titleEn: 'Artashat',
    titleRu: 'Арташат',
    titlePe: 'آرتاشات',
    regionId: 2
  },
  { id: 9, titleHy: 'Արթիկ', titleEn: 'Artik', titleRu: 'Артик', titlePe: 'آرتیک', regionId: 8 },
  {
    id: 10,
    titleHy: 'Աշոցք',
    titleEn: 'Ashotsk',
    titleRu: 'Ашоцк',
    titlePe: 'آشوتسک',
    regionId: 8
  },
  {
    id: 11,
    titleHy: 'Աշտարակ',
    titleEn: 'Ashtarak',
    titleRu: 'Аштарак',
    titlePe: 'آشتاراک',
    regionId: 1
  },
  { id: 12, titleHy: 'Վայք', titleEn: 'Vayk', titleRu: 'Вайк', titlePe: 'وایک', regionId: 10 },
  {
    id: 13,
    titleHy: 'Վանաձոր',
    titleEn: 'Vanadzor',
    titleRu: 'Ванадзор',
    titlePe: 'وانادزور',
    regionId: 6
  },
  {
    id: 14,
    titleHy: 'Վարդենիս',
    titleEn: 'Vardenis',
    titleRu: 'Варденис',
    titlePe: 'واردنیس',
    regionId: 4
  },
  { id: 15, titleHy: 'Գավառ', titleEn: 'Gavar', titleRu: 'Гавар', titlePe: 'گاوار', regionId: 4 },
  { id: 16, titleHy: 'Գորիս', titleEn: 'Goris', titleRu: 'Горис', titlePe: 'گوریس', regionId: 9 },
  {
    id: 17,
    titleHy: 'Հրազդան',
    titleEn: 'Hrazdan',
    titleRu: 'Раздан',
    titlePe: 'هِرازدان',
    regionId: 7
  },
  {
    id: 18,
    titleHy: 'Գյումրի',
    titleEn: 'Gyumri',
    titleRu: 'Гюмри',
    titlePe: 'گیومری',
    regionId: 8
  },
  {
    id: 19,
    titleHy: 'Ջերմուկ',
    titleEn: 'Jermuk',
    titleRu: 'Джермук',
    titlePe: 'جِرموک',
    regionId: 10
  },
  {
    id: 20,
    titleHy: 'Դիլիջան',
    titleEn: 'Dilijan',
    titleRu: 'Дилиджан',
    titlePe: 'دیلیجان',
    regionId: 11
  },
  {
    id: 21,
    titleHy: 'Եղվարդ',
    titleEn: 'Yeghvard',
    titleRu: 'Егвард',
    titlePe: 'یِقوارد',
    regionId: 7
  },
  {
    id: 22,
    titleHy: 'Եզեգնաձոր',
    titleEn: 'Yeghegnadzor',
    titleRu: 'Егегнадзор',
    titlePe: 'یِقِگ نادزور',
    regionId: 10
  },
  {
    id: 24,
    titleHy: 'Իջևան',
    titleEn: 'Ijevan',
    titleRu: 'Иджеван',
    titlePe: 'ایجِوان',
    regionId: 11
  },
  { id: 25, titleHy: 'Կապան', titleEn: 'Kapan', titleRu: 'Капан', titlePe: 'کاپان', regionId: 9 },
  {
    id: 26,
    titleHy: 'Մարալիկ',
    titleEn: 'Maralik',
    titleRu: 'Маралик',
    titlePe: 'مارالیک',
    regionId: 8
  },
  {
    id: 27,
    titleHy: 'Մարտունի',
    titleEn: 'Martuni',
    titleRu: 'Мартуни',
    titlePe: 'مارتونی',
    regionId: 4
  },
  { id: 28, titleHy: 'Մասիս', titleEn: 'Masis', titleRu: 'Масис', titlePe: 'ماسیس', regionId: 2 },
  { id: 29, titleHy: 'Մեղրի', titleEn: 'Meghri', titleRu: 'Мегри', titlePe: 'مِقری', regionId: 9 },
  {
    id: 30,
    titleHy: 'Նոյեմբերյան',
    titleEn: 'Noyemberyan',
    titleRu: 'Ноемберян',
    titlePe: 'نویِمبِریان',
    regionId: 11
  },
  { id: 31, titleHy: 'Սևան', titleEn: 'Sevan', titleRu: 'Севан', titlePe: 'سِوان', regionId: 4 },
  {
    id: 33,
    titleHy: 'Սպիտակ',
    titleEn: 'Spitak',
    titleRu: 'Спитак',
    titlePe: 'اِسپیتاک',
    regionId: 6
  },
  {
    id: 34,
    titleHy: 'Ստեփանավան',
    titleEn: 'Stepanavan',
    titleRu: 'Степанаван',
    titlePe: 'اِستِپاناوان',
    regionId: 6
  },
  {
    id: 35,
    titleHy: 'Տավուշ',
    titleEn: 'Tavush (Berd)',
    titleRu: 'Тавуш',
    titlePe: '(تاووش (بِرد',
    regionId: 11
  },
  { id: 36, titleHy: 'Թալին', titleEn: 'Talin', titleRu: 'Талин', titlePe: 'تالین', regionId: 1 },
  { id: 37, titleHy: 'Տաշիր', titleEn: 'Tashir', titleRu: 'Ташир', titlePe: 'تَشیر', regionId: 6 },
  {
    id: 38,
    titleHy: 'Ճամբարակ',
    titleEn: 'Chambarak',
    titleRu: 'Чамбарак',
    titlePe: 'چامباراک',
    regionId: 4
  },
  {
    id: 39,
    titleHy: 'Չարենցավան',
    titleEn: 'Charentsavan',
    titleRu: 'Чаренцаван',
    titlePe: 'چارِنت ساوان',
    regionId: 7
  },
  {
    id: 40,
    titleHy: 'Էջմիածին',
    titleEn: 'Echmiadzin',
    titleRu: 'Эчмиадзин',
    titlePe: 'اِچمیادزین',
    regionId: 3
  },
  {
    id: 43,
    titleHy: 'Ստեպանակերտ',
    titleEn: 'Stepanakert',
    titleRu: 'Степанакерт',
    titlePe: 'اِستِپاناکِرت',
    regionId: 12
  },
  { id: 53, titleHy: 'Շուշի', titleEn: 'Shushi', titleRu: 'Шуша', titlePe: 'شوشی', regionId: 12 },
  {
    id: 54,
    titleHy: 'Պռոշյան',
    titleEn: 'Proshyan',
    titleRu: 'Прошян',
    titlePe: 'پِروشیان',
    regionId: 7
  },
  {
    id: 55,
    titleHy: 'Սիսիան',
    titleEn: 'Sisian',
    titleRu: 'Сисиан',
    titlePe: 'سیسیان',
    regionId: 9
  },
  {
    id: 56,
    titleHy: 'Աշտարակ',
    titleEn: 'Ashtarak',
    titleRu: 'Аштарак',
    titlePe: 'آشتاراک',
    regionId: 7
  },
  { id: 57, titleHy: 'Արզնի', titleEn: 'Arzni', titleRu: 'Арзни', titlePe: 'Arzni', regionId: 7 },
  {
    id: 74,
    titleHy: 'Օշական',
    titleEn: 'Oshakan',
    titleRu: 'Oшакан',
    titlePe: 'Oshakan',
    regionId: 1
  },
  {
    id: 75,
    titleHy: 'Ջրվեժ',
    titleEn: 'Jrvejh',
    titleRu: 'Джрвеж',
    titlePe: 'Jrvejh',
    regionId: 7
  },
  {
    id: 76,
    titleHy: 'Ձորաղբյուր',
    titleEn: 'Dzoraghbyur',
    titleRu: 'ДЗОРАХПЮР',
    titlePe: 'Dzoraghbyur',
    regionId: 7
  },
  {
    id: 77,
    titleHy: 'Զովունի',
    titleEn: 'Zovuni',
    titleRu: 'Зовуни',
    titlePe: 'Zovuni',
    regionId: 7
  },
  {
    id: 78,
    titleHy: 'Բյուրեղավան',
    titleEn: 'Byureghavan',
    titleRu: 'Бюрегаван',
    titlePe: 'Byureghavan',
    regionId: 7
  },
  {
    id: 79,
    titleHy: 'Ծաղկաձոր',
    titleEn: 'Tsaghkadzor',
    titleRu: 'Цахкадзор',
    titlePe: 'Tsaghkadzor',
    regionId: 7
  },
  {
    id: 80,
    titleHy: 'Հայանիստ',
    titleEn: 'Hayanist',
    titleRu: 'Аянист',
    titlePe: 'Hayanist',
    regionId: 2
  },
  {
    id: 81,
    titleHy: 'Օհանավան',
    titleEn: 'Ohanavan',
    titleRu: 'Оганаван',
    titlePe: 'Ohanavan',
    regionId: 1
  },
  {
    id: 82,
    titleHy: 'Ոսկեվազ',
    titleEn: 'Voskevaz',
    titleRu: 'Воскеваз',
    titlePe: 'Voskevaz',
    regionId: 1
  },
  { id: 83, titleHy: 'Գառնի', titleEn: 'Garni', titleRu: 'Гарни', titlePe: 'Garni', regionId: 7 },
  {
    id: 84,
    titleHy: 'Արա լեռ',
    titleEn: 'Ara ler',
    titleRu: 'Ара лер',
    titlePe: 'Ara ler',
    regionId: 1
  },
  {
    id: 85,
    titleHy: 'Արտաշավան',
    titleEn: 'Artashavan',
    titleRu: 'Арташаван',
    titlePe: 'Artashavan',
    regionId: 1
  },
  {
    id: 86,
    titleHy: 'Բազմաղբյուր',
    titleEn: 'Bazmaghbyur',
    titleRu: 'Базмагбйур',
    titlePe: 'Bazmaghbyur',
    regionId: 1
  },
  {
    id: 87,
    titleHy: 'Ձորափ',
    titleEn: 'Dzorap',
    titleRu: 'Дзорап',
    titlePe: 'Dzorap',
    regionId: 1
  },
  { id: 88, titleHy: 'Առինջ', titleEn: 'Arinj', titleRu: 'Ариндж', titlePe: 'Arinj', regionId: 7 },
  {
    id: 89,
    titleHy: 'Խարբերդ',
    titleEn: 'Xarberd',
    titleRu: 'Харберд',
    titlePe: 'Xarberd',
    regionId: 2
  },
  {
    id: 90,
    titleHy: 'Քանաքեռ Ավան',
    titleEn: 'Qanaqer Avan',
    titleRu: 'Канакер Аван',
    titlePe: 'Qanaqer Avan',
    regionId: 7
  },
  {
    id: 91,
    titleHy: 'Փարաքար',
    titleEn: 'Paraqar',
    titleRu: 'Паракар',
    titlePe: 'Paraqar',
    regionId: 3
  },
  { id: 93, titleHy: 'Պտղնի', titleEn: 'Ptghni', titleRu: 'Птгни', titlePe: 'Ptghni', regionId: 7 },
  {
    id: 94,
    titleHy: 'Սևան-Երևան մայրուղի',
    titleEn: 'Sevan - Yerevan',
    titleRu: 'Севан- Ереван',
    titlePe: 'Sevan - Yerevan',
    regionId: 7
  },
  {
    id: 95,
    titleHy: 'Գեղադիր',
    titleEn: 'Gexadir',
    titleRu: 'Гегадир',
    titlePe: 'Gexadir',
    regionId: 7
  },
  {
    id: 96,
    titleHy: 'Բալահովիտ',
    titleEn: 'Balahovit',
    titleRu: 'Балаовит',
    titlePe: 'Balahovit',
    regionId: 7
  },
  { id: 97, titleHy: 'Բջնի', titleEn: 'Bjni', titleRu: 'Бжни', titlePe: 'Bjni', regionId: 7 },
  {
    id: 98,
    titleHy: 'Բյուրական',
    titleEn: 'Byurakan',
    titleRu: 'Бюракан',
    titlePe: 'Byurakan',
    regionId: 1
  },
  {
    id: 99,
    titleHy: 'Արզական',
    titleEn: 'Arzakan',
    titleRu: 'Арзакан',
    titlePe: 'Arzakan',
    regionId: 7
  },
  {
    id: 100,
    titleHy: 'գյուղ Պտղունք',
    titleEn: 'Ptghunq vil',
    titleRu: 'Птхунк',
    titlePe: 'Ptghunq vil',
    regionId: 3
  },
  {
    id: 101,
    titleHy: 'Հանքավան',
    titleEn: 'Hanqavan',
    titleRu: 'Анкаван',
    titlePe: 'Hanqavan',
    regionId: 7
  },
  {
    id: 102,
    titleHy: 'Քասախ',
    titleEn: 'Qasakh',
    titleRu: 'Касах',
    titlePe: 'Qasakh',
    regionId: 7
  },
  {
    id: 103,
    titleHy: 'Բազմաղբյուր',
    titleEn: 'Bazmaghbyur',
    titleRu: 'Базмахбюр',
    titlePe: 'Bazmaghbyur',
    regionId: 7
  },
  {
    id: 104,
    titleHy: 'Նոր Հաճն',
    titleEn: 'Nor Hachn',
    titleRu: 'Нор Ачн',
    titlePe: 'Nor Hachn',
    regionId: 7
  },
  {
    id: 105,
    titleHy: 'Ախուրյան գյուղ',
    titleEn: 'Akhuryan',
    titleRu: 'Ахурян',
    titlePe: 'Akhuryan',
    regionId: 8
  },
  { id: 106, titleHy: 'Կարբի', titleEn: 'Karbi', titleRu: 'Карби', titlePe: 'کاربی', regionId: 1 },
  {
    id: 107,
    titleHy: 'Փարպի գյուղ',
    titleEn: 'Parpi vil.',
    titleRu: 'Парпи',
    titlePe: 'Parpi vil.',
    regionId: 1
  },
  {
    id: 108,
    titleHy: 'Թաիրով',
    titleEn: 'Tairov',
    titleRu: 'Таиров',
    titlePe: 'Tairov',
    regionId: 3
  },
  {
    id: 109,
    titleHy: 'գյ. Մայակովսկի',
    titleEn: 'Mayakovski vil.',
    titleRu: 'Маяковски д.',
    titlePe: 'Mayakovski vil.',
    regionId: 7
  },
  {
    id: 110,
    titleHy: 'Տեղեր',
    titleEn: 'Tegher',
    titleRu: 'Техер',
    titlePe: 'Tegher',
    regionId: 1
  },
  {
    id: 111,
    titleHy: 'գ. Սաղնոսավան',
    titleEn: 'vil. Saghnosavan',
    titleRu: 'Сахносаван',
    titlePe: 'vil. Saghnosavan',
    regionId: 1
  },
  {
    id: 112,
    titleHy: 'գ. Ավագյան',
    titleEn: 'Avagyan vil.',
    titleRu: 'Авагян',
    titlePe: 'Avagyan vil.',
    regionId: 7
  },
  {
    id: 113,
    titleHy: 'Աղվերան',
    titleEn: 'Aghveran',
    titleRu: 'Ахверан',
    titlePe: 'Aghveran',
    regionId: 7
  },
  {
    id: 114,
    titleHy: 'Բաղրամյան',
    titleEn: 'Baghramyan',
    titleRu: 'Баграмян',
    titlePe: 'Baghramyan',
    regionId: 3
  },
  {
    id: 115,
    titleHy: 'գ.Ակունք',
    titleEn: 'v.Akunq',
    titleRu: 'д.Акунк',
    titlePe: 'v.Akunq',
    regionId: 7
  },
  {
    id: 116,
    titleHy: 'Մեծամոր',
    titleEn: 'Metsamor',
    titleRu: 'Метьамор',
    titlePe: 'Metsamor',
    regionId: 3
  },
  { id: 117, titleHy: 'Վեդի', titleEn: 'Vedi', titleRu: 'Веды', titlePe: 'Vedi', regionId: 2 },
  {
    id: 118,
    titleHy: 'Նոր Գեղի',
    titleEn: 'Nor Geghi',
    titleRu: 'Nor Geghi',
    titlePe: 'Nor Geghi',
    regionId: 7
  },
  {
    id: 119,
    titleHy: 'Հացավան',
    titleEn: 'Hacavan',
    titleRu: 'Hacavan',
    titlePe: 'Hacavan',
    regionId: 7
  },
  {
    id: 120,
    titleHy: 'Կաթնաղբյուր',
    titleEn: 'Katnaghbyur\t',
    titleRu: 'Катнахбюр',
    titlePe: 'Katnaghbyur\t',
    regionId: 1
  },
  {
    id: 121,
    titleHy: 'Արալեզ',
    titleEn: 'Aralez',
    titleRu: 'Аралез',
    titlePe: 'Aralez',
    regionId: 2
  },
  {
    id: 122,
    titleHy: 'Կարմիրգյուղ',
    titleEn: 'Karmirgyugh',
    titleRu: 'Кармиргюх',
    titlePe: 'Karmirgyugh',
    regionId: 4
  },
  {
    id: 123,
    titleHy: 'Մարտունի',
    titleEn: 'Martuni',
    titleRu: 'Мартуни',
    titlePe: 'Martuni',
    regionId: 12
  },
  {
    id: 124,
    titleHy: 'Թեղենիք',
    titleEn: 'Teghenik',
    titleRu: 'Техеник',
    titlePe: 'Teghenik',
    regionId: 7
  },
  {
    id: 125,
    titleHy: 'Նոր գյուղ',
    titleEn: 'Nor gyugh',
    titleRu: 'Нор гюх',
    titlePe: 'Nor gyugh',
    regionId: 7
  },
  { id: 126, titleHy: 'Այնթապ', titleEn: 'AYNTAP', titleRu: 'AYNTAP', titlePe: '', regionId: 2 },
  {
    id: 127,
    titleHy: 'Մրգաստան',
    titleEn: 'Mrgastan',
    titleRu: 'Mrgastan',
    titlePe: '',
    regionId: 3
  },
  {
    id: 128,
    titleHy: 'գյուղ Ուջան',
    titleEn: 'Ujan village',
    titleRu: 'село Уджан',
    titlePe: 'Ujan village',
    regionId: 1
  },
  {
    id: 129,
    titleHy: 'Գետարգել գյուղ',
    titleEn: 'Getargel village',
    titleRu: 'село Гетаргел',
    titlePe: 'Getargel village',
    regionId: 7
  },
  {
    id: 130,
    titleHy: 'Քանաքեռավան գյուղ',
    titleEn: 'Qanaqeravan village',
    titleRu: 'село Канакераван',
    titlePe: 'Qanaqeravan village',
    regionId: 7
  },
  {
    id: 131,
    titleHy: 'Գեղակերտ գյուղ',
    titleEn: 'Geghakert village',
    titleRu: 'село Гегакерт',
    titlePe: 'Geghakert village',
    regionId: 3
  },
  {
    id: 132,
    titleHy: 'Մերձավան գյուղ',
    titleEn: 'Merdzavan village',
    titleRu: 'Merdzavan village',
    titlePe: 'Merdzavan village',
    regionId: 3
  },
  {
    id: 133,
    titleHy: 'գ. Գեղանիստ',
    titleEn: 'Geghanist',
    titleRu: 'Geghanist',
    titlePe: 'Geghanist',
    regionId: 2
  },
  {
    id: 134,
    titleHy: 'Զորավան',
    titleEn: 'Zoravan',
    titleRu: 'Зораван',
    titlePe: 'Zoravan',
    regionId: 7
  },
  {
    id: 135,
    titleHy: 'Նոր Կյանք',
    titleEn: 'Nor Kyanq',
    titleRu: 'Нор Кианк',
    titlePe: 'Nor Kyanq',
    regionId: 2
  },
  {
    id: 136,
    titleHy: 'Խաչփառ',
    titleEn: 'Xachpar',
    titleRu: 'Хачпар',
    titlePe: 'Xachpar',
    regionId: 2
  },
  { id: 137, titleHy: 'Զովք', titleEn: 'Zovq', titleRu: 'Зовк', titlePe: 'Zovq', regionId: 7 },
  {
    id: 138,
    titleHy: 'Գետարգել գյուղ',
    titleEn: 'Getargel village',
    titleRu: 'село Гетаргел',
    titlePe: 'Getargel village',
    regionId: 7
  },
  {
    id: 139,
    titleHy: 'Տափերական',
    titleEn: 'Taperakan',
    titleRu: 'Таперакан',
    titlePe: 'Taperakan',
    regionId: 2
  },
  {
    id: 140,
    titleHy: 'Քարաշամբ',
    titleEn: 'Qarashamb',
    titleRu: 'Карашамб',
    titlePe: 'Qarashamb',
    regionId: 7
  },
  { id: 141, titleHy: 'Արգել', titleEn: 'Argel', titleRu: 'Аргел', titlePe: 'Argel', regionId: 7 },
  {
    id: 142,
    titleHy: 'Ջրաշեն',
    titleEn: 'Jrashen',
    titleRu: 'Джрашен',
    titlePe: 'Jrashen',
    regionId: 2
  },
  {
    id: 143,
    titleHy: 'Նոր Արտամեդ',
    titleEn: 'Nor Artamed',
    titleRu: 'Нор Артамед',
    titlePe: 'Nor Artamed',
    regionId: 7
  },
  {
    id: 144,
    titleHy: 'Գետամեջ',
    titleEn: 'Getamej',
    titleRu: 'Гетамедж',
    titlePe: 'Getamej',
    regionId: 7
  },
  {
    id: 145,
    titleHy: 'Նուռնուս',
    titleEn: 'Nurnus',
    titleRu: 'Нурнус',
    titlePe: 'Nurnus',
    regionId: 7
  },
  {
    id: 146,
    titleHy: 'Բաղաբերդ',
    titleEn: 'Baghaberd',
    titleRu: 'Багаберд',
    titlePe: 'Baghaberd',
    regionId: 9
  },
  { id: 147, titleHy: 'Առինջ', titleEn: 'Arinj', titleRu: 'Ариндж', titlePe: 'Arinj', regionId: 1 },
  {
    id: 148,
    titleHy: 'Հ․ Շահումյան',
    titleEn: 'H. Shahumyan',
    titleRu: 'Шаумян',
    titlePe: 'H. Shahumyan',
    regionId: 2
  },
  {
    id: 149,
    titleHy: 'Գեղարքունիք',
    titleEn: 'Gegharkunik',
    titleRu: 'Гегаркуник',
    titlePe: 'Gegharkunik',
    regionId: 4
  },
  {
    id: 150,
    titleHy: 'Կամարիս',
    titleEn: 'Kamaris',
    titleRu: 'Камарис',
    titlePe: 'Kamaris',
    regionId: 7
  },
  {
    id: 151,
    titleHy: 'գ. Լճաշեն',
    titleEn: 'Lchashen village',
    titleRu: 'Лчашен село',
    titlePe: 'Lchashen village',
    regionId: 4
  },
  {
    id: 152,
    titleHy: 'Վերին Արտաշատ',
    titleEn: 'Verin Artashat',
    titleRu: 'Верин Арташат',
    titlePe: 'Verin Artashat',
    regionId: 2
  },
  {
    id: 153,
    titleHy: 'Քաջարան',
    titleEn: 'Kajaran',
    titleRu: 'Каджаран',
    titlePe: 'Kajaran',
    regionId: 9
  },
  { id: 154, titleHy: 'Բերդ', titleEn: 'Berd', titleRu: 'Берд', titlePe: 'Berd', regionId: 11 }
];
