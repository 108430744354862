import { Dispatch, SetStateAction } from 'react';
import { FIStype } from './types';

export interface isOpenType {
  [key: string]: boolean;
}

export const handleChangeKeyValue = (
  setState: Dispatch<SetStateAction<FIStype>>,
  key: keyof FIStype,
  label: string,
  value: string
) => {
  setState((prev: any) => ({ ...prev, [key]: { ...prev[key], [label]: value } }));
};

export const handleChangeLabelValue = (
  setState: Dispatch<SetStateAction<FIStype>>,
  key: keyof FIStype,
  label: string,
  value: string
) => {
  setState((prev) => ({ ...prev, [key]: { label, value } }));
};

export const handleChangeLabelValueArr = (
  setState: Dispatch<SetStateAction<FIStype>>,
  state: FIStype,
  key: keyof FIStype,
  label: string,
  value: string
) => {
  if (key === 'filter4' || key === 'filter5') {
    if (state[key].some((item: any) => item.label === label)) {
      setState((prev) => ({
        ...prev,
        [key]: prev[key].filter((item: any) => item.label !== label)
      }));
    } else {
      return setState((prev) => ({ ...prev, [key]: [...prev[key], { label, value }] }));
    }
  }
};

export const handleIdArrChange = (
  setState: Dispatch<SetStateAction<FIStype>>,
  state: any,
  type: string,
  id: number
) => {
  const currentState: number[] = state[type];
  if (currentState.includes(id)) {
    setState((prev) => ({ ...prev, [type]: currentState.filter((item: number) => item !== id) }));
  } else {
    setState((prev) => ({ ...prev, [type]: [...currentState, id] }));
  }
};

export const handleChangePriceMinMax = (
  setState: Dispatch<SetStateAction<FIStype>>,
  key: keyof FIStype,
  label: string,
  value: string
) => {
  setState((prev) => ({ ...prev, [key]: { ...prev[key], [label]: value } }));
};

export const handleOpenMenu = (
  isOpen: isOpenType,
  setIsOpen: Dispatch<SetStateAction<isOpenType>>,
  key: keyof FIStype
) => {
  setIsOpen(
    Object.fromEntries(Object.keys(isOpen).map((k) => (k === key ? [k, !isOpen[k]] : [k, false])))
  );
};

export const handleCloseMenu = (
  isOpen: isOpenType,
  setIsOpen: Dispatch<SetStateAction<isOpenType>>
) => {
  const allFalse = Object.values(isOpen).every((value) => value === false);
  !allFalse && setIsOpen(Object.fromEntries(Object.keys(isOpen).map((k) => [k, false])));
};
