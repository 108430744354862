import { MenuArrow } from '@/assets/svgComponents/menuArrow';
import { PhoneIcon } from '@/assets/svgComponents/phoneIcon';
import { Link } from '@/helpers/navigation';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { LanguageSelect } from './languageSelect';
import s from './style.module.scss';

interface IMenu {
  handleLoginModal: (e: React.SyntheticEvent) => void;
}

export const Menu = ({ handleLoginModal }: IMenu) => {
  const t = useTranslations('');
  const [isopen, setIsopen] = useState([false, false, false]);

  const handleIsopen = (e: React.MouseEvent, key: number) => {
    e.stopPropagation();
    if (key !== 2) {
      setIsopen((prev) => prev.map((v, i) => (i === 2 ? true : i === key ? !v : false)));
    } else {
      setIsopen((prev) => prev.map((v, i) => (i === key ? !v : false)));
    }
  };

  const handleCloseMenu = () => {
    setIsopen((prev) => prev.map(() => false));
  };

  useEffect(() => {
    window.addEventListener('click', handleCloseMenu);
    return () => window.removeEventListener('click', handleCloseMenu);
  }, []);

  return (
    <div className={s.menu} onClick={(e) => handleIsopen(e, 2)}>
      <button>
        <span>&#8228;</span>
        <span>&#8228;</span>
        <span>&#8228;</span>
      </button>
      <ul data-open={isopen[2]}>
        <li className={s.mobileTopBlock}>
          <Link href='tel:+37444205090' className={s.phone}>
            <PhoneIcon />
            <span>+(374) 44 205090</span>
          </Link>
          <LanguageSelect />
          <Link href='/submit-listing' className={s.add} onClick={handleLoginModal}>
            <b>+</b>
            <span>{t('add')}</span>
          </Link>
        </li>
        <li className={s.tabletNav} onClick={(e) => handleIsopen(e, 0)} data-open={isopen[0]}>
          <span>
            {t('sale')} <MenuArrow />
          </span>
          <ul className={s.subMenu}>
            <li>
              <Link href='/'>{t('sale_of_apartments')}</Link>
            </li>
            <li>
              <Link href='/'>{t('sale_of_houses')}</Link>
            </li>
            <li>
              <Link href='/'>{t('sale_of_commercial')}</Link>
            </li>
            <li>
              <Link href='/'>{t('sale_of_lands')}</Link>
            </li>
          </ul>
        </li>
        <li className={s.tabletNav} onClick={(e) => handleIsopen(e, 1)} data-open={isopen[1]}>
          <span>
            {t('rent')} <MenuArrow />
          </span>
          <ul className={s.subMenu}>
            <li>
              <Link href='/'>{t('rent_of_apartments')}</Link>
            </li>
            <li>
              <Link href='/'>{t('rent_of_houses')}</Link>
            </li>
            <li>
              <Link href='/'>{t('rent_of_commercial')}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link href='/news'>{t('news')}</Link>
        </li>
        <li>
          <Link href='/blog'>{t('blog')}</Link>
        </li>
        <li>
          <Link href='/contact-us'>{t('contact_us')}</Link>
        </li>
      </ul>
    </div>
  );
};
