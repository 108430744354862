'use client';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import s from './style.module.scss';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}

const initialState: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: ''
};

export const Form = () => {
  const t = useTranslations('');
  const [form, setForm] = useState(initialState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className={s.form}>
      <input
        required
        type='text'
        name='firstName'
        placeholder={t('name')}
        onChange={handleChange}
      />
      <input
        required
        type='text'
        name='lastName'
        placeholder={t('surname')}
        onChange={handleChange}
      />
      <input required type='mail' name='email' placeholder={t('email')} onChange={handleChange} />
      <input
        required
        type='phone'
        name='phone'
        placeholder={t('phone_number')}
        onChange={handleChange}
      />
      <textarea required name='message' placeholder={t('your_message')} onChange={handleChange} />
      <button type='submit'>{t('send')}</button>
    </form>
  );
};
