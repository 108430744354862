'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeIsOpen } from '@/Store/slices/favorites.slice';
import { useEffect } from 'react';
import s from './style.module.scss';

export const FavoritesModal = () => {
  const dispatch = useTDispatch();
  const { isOpen } = useTSelector((state) => state.favorites);

  const handleCloseModal = () => {
    dispatch(changeIsOpen(false));
  };

  useEffect(() => {
    const status = localStorage.getItem('theme') || 'light';
    document.body.setAttribute('data-theme', status);
  }, []);

  return (
    <>
      {isOpen && (
        <div className={s.favoriteModal} onClick={handleCloseModal}>
          <div className={s.container} onClick={(e) => e.stopPropagation()}>
            <button className={s.close} onClick={handleCloseModal}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};
